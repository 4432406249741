<template>
  <aside class="e-sidebar">
    <BackButton v-if="showBackButton || showShortBackButton" />
    <template v-if="!gdprConfirmationNeeded">
      <div
        v-if="userInfo && userInfo.id"
        class="e-sidebar__user"
      >
        {{ $t('system.hello') }}, <strong>{{ userInfo.username }}</strong>
      </div>
    </template>

    <MessageNav v-if="userInfo && userInfo.id" />
    <AccountNav v-if="userInfo && userInfo.id" />
  </aside>
</template>

<script>
import { mapGetters } from 'vuex'

import AccountNav from 'atoms/Accountnav/Accountnav'
import BackButton from 'atoms/BackButton/BackButton'
import MessageNav from 'atoms/Messagenav/Messagenav'

export default {
  name: 'TheSidebar',
  components: {
    AccountNav,
    BackButton,
    MessageNav
  },
  computed: {
    ...mapGetters(['userInfo', 'currentRouteName', 'gdprConfirmationNeeded']),

    showBackButton () {
      return !this.userInfo && (this.currentRouteName === 'content' || this.currentRouteName === 'support')
    },
    showShortBackButton () {
      return this.gdprConfirmationNeeded && this.currentRouteName !== 'gdpr'
    }
  }
}
</script>

<style lang="scss">
@import '_assets/sidebar';
</style>
