
<template>
  <span v-html="message" />
</template>

<script>
import { subMilliseconds, distanceInWordsStrict } from 'date-fns'
import { mapGetters } from 'vuex'

export default {
  props: {
    idleTime: {
      type: Number,
      default: () => 2000
    },
    idleTimeBuffer: {
      type: Number,
      default: () => 1000
    },
    i18n: {
      type: Object,
      default: () => { }
    }
  },
  computed: {
    ...mapGetters(['dateLocale'])
  },
  data: () => ({
    message: null,
    timerId: null,
    idleDate: null,
    idleBufferDate: null,
    counter: 0
  }),

  mounted () {
    this.startTimer()
  },

  destroyed () {
    window.clearTimeout(this.timerId)
  },

  methods: {
    t (key) {
      let values = []
      let len = arguments.length - 1
      while (len-- > 0) values[len] = arguments[len + 1]
      const i18n = this.i18n
      return i18n._t.apply(i18n, [key, i18n.locale, i18n._getMessages(), this].concat(values))
    },
    startTimer () {
      this.idleDate = subMilliseconds(new Date(), this.idleTime)
      this.counter = this.idleTimeBuffer
      this.updateDates()
    },

    updateDates () {
      window.clearTimeout(this.timerId)
      this.idleBufferDate = subMilliseconds(new Date(), this.counter)
      this.message = this.t('chat.idleConfirmation.message', {
        idleTime: distanceInWordsStrict(new Date(), this.idleDate, { locale: this.dateLocale, addSuffix: true }),
        closeTime: distanceInWordsStrict(this.idleBufferDate, new Date(), { locale: this.dateLocale, addSuffix: true })
      })
      this.counter = this.counter - 1000
      if (this.counter > 0) {
        this.timerId = window.setTimeout(this.updateDates, 1000)
        return
      }
      this.$emit('countdown-ended')
    }
  }
}

</script>
